import React from 'react';
import Select from 'react-select';
import { Link } from "gatsby"
import "./styles.scss"

interface HeaderProps {
  language: string,
  country: string,
  urlParts: [string]
};

const Header:React.FC<HeaderProps> = ({
  language,
  country,
  urlParts
}) => {

  const options = [
      { value: "en", label: "en" },
      { value: "jp", label: "jp" }
  ];

  const switchToLanguage = (toLang, urlParts) => {
    // change part of URL
    

    // if we're on a homepage, then just change to the other homepage
    //const urlParts = window.location.pathname.split("/").filter(e => e);
     if(urlParts.length > 2) {
      return "/" + urlParts[0] + "/" + toLang + "/" + urlParts.slice(2).join("/") + "/";
    }
    
    // if in doubt, back to the home page
    return "/" + toLang + "/";
 
  }

  return (
    <div className="header">
        <Link to={"/"+country}><img src={'/logo-' + country + ".svg"} /></Link>       
        <div className="language-switcher">          
            <Link to={switchToLanguage("en", urlParts)} className={language == "en" ? "active" : ""}><img src="/flag-en.svg" alt="UK flag"/></Link>
            <Link to={switchToLanguage("jp", urlParts)} className={language == "jp" ? "active" : ""}><img src="/flag-jp.svg" alt="UK flag"/></Link>
        </div>
    </div>
  )
};

export default Header;
